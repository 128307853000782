import React from "react"
import Layout from "gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import { Helmet } from "react-helmet"

import logo from "../images/logo-bfsp.png"

const IndexPage = () => {
  const body = [
    {
      component: "hero_block",
      image: {
        filename: "https://picsum.photos/1920/1080",
      },
      venue: "burgfestspiele",
    },
    {
      component: "folder_block",
      folder_items: [
        {
          title: "Titel vom ersten Tab",
          content: [
            {
              component: "image_block",
              image: { filename: "https://picsum.photos/1920/1080" },
            },
          ],
        },
        {
          title: "Titel vom zweiten Tab",
          content: [
            {
              component: "text_block",
              headline: "Aktuell Sommer 2020",
              text:
                "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken. ",
            },
          ],
        },
        {
          title: "Titel vom dritten Tab",
          content: [
            {
              component: "text_image_block",
              image: { filename: "https://picsum.photos/960/540" },
              headline: "Aktuell Sommer 2020",
              text:
                "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken. ",
            },
          ],
        },
      ],
    },
    {
      component: "masonry_grid_block",
      cards: [
        {
          image: { filename: "https://picsum.photos/600/600" },
          category: "Burgfestspiele",
          type: "Theater",
          headline: "Theaterstück mit langem Titel 1",
          date: "7.7.-8.8.",
        },
        {
          image: { filename: "https://picsum.photos/600/400" },
          category: "Burgfestspiele",
          type: "Theater",
          headline: "Theaterstück 2",
          date: "7.7.-8.8.",
        },
        {
          image: { filename: "https://picsum.photos/600/900" },
          category: "Burgfestspiele",
          type: "Theater",
          headline: "Theaterstück mit Titel 3",
          date: "7.7.-8.8.",
        },
        {
          image: { filename: "https://picsum.photos/600/400" },
          category: "Burgfestspiele",
          type: "Theater",
          headline: "Theaterstück mit Titel 4",
          date: "7.7.-8.8.",
        },
        {
          image: { filename: "https://picsum.photos/600/900" },
          category: "Burgfestspiele",
          type: "Theater",
          headline: "Theaterstück mit langem Titel 5",
          date: "7.7.-8.8.",
        },
        {
          image: { filename: "https://picsum.photos/600/600" },
          category: "Burgfestspiele",
          type: "Theater",
          headline: "Theaterstück mit Titel 6",
          date: "7.7.-8.8.",
        },
        {
          image: { filename: "https://picsum.photos/600/900" },
          category: "Burgfestspiele",
          type: "Theater",
          headline: "Theaterstück 7",
          date: "7.7.-8.8.",
        },
        {
          image: { filename: "https://picsum.photos/600/600" },
          category: "Burgfestspiele",
          type: "Theater",
          headline: "Theaterstück mit sehr, sehr, sehr langem Titel 8",
          date: "7.7.-8.8.",
        },
        {
          image: { filename: "https://picsum.photos/600/400" },
          category: "Burgfestspiele",
          type: "Theater",
          headline: "Theaterstück mit Titel 9",
          date: "7.7.-8.8.",
        },
      ],
    },
    {
      component: "gallery_block",
      headline: "Beispiel-Gallery",
      view: "thumbnails",
      image_items: [
        {
          image: { filename: "https://picsum.photos/1200/800" },
          caption: "Bild-Beschreibung vom 1. Bild",
        },
        {
          image: { filename: "https://picsum.photos/800/1200" },
          caption: "Bild-Beschreibung vom 2. Bild",
        },
        {
          image: { filename: "https://picsum.photos/1200/1200" },
          caption: "Bild-Beschreibung vom 3. Bild",
        },
        {
          image: { filename: "https://picsum.photos/1200/800" },
          caption: "Bild-Beschreibung vom 4. Bild",
        },
        {
          image: { filename: "https://picsum.photos/800/1200" },
          caption: "Bild-Beschreibung vom 5. Bild",
        },
        {
          image: { filename: "https://picsum.photos/1200/1200" },
          caption: "Bild-Beschreibung vom 6. Bild",
        },
        {
          image: { filename: "https://picsum.photos/1200/800" },
          caption: "Bild-Beschreibung vom 7. Bild",
        },
        {
          image: { filename: "https://picsum.photos/800/1200" },
          caption: "Bild-Beschreibung vom 8. Bild",
        },
        {
          image: { filename: "https://picsum.photos/1200/1200" },
          caption: "Bild-Beschreibung vom 9. Bild",
        },
      ],
    },
    {
      component: "text_image_block",
      image: { filename: "https://picsum.photos/960/540" },
      headline: "Aktuell Sommer 2020",
      text:
        "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken. ",
    },
    {
      component: "text_image_block",
      text_position: "left",
      image: { filename: "https://picsum.photos/960/540" },
      headline: "Aktuell Sommer 2020",
      text:
        "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken. ",
    },
    {
      component: "image_block",
      block_width: "content",
      image: { filename: "https://picsum.photos/1920/1080" },
    },
    {
      component: "image_block",
      image: { filename: "https://picsum.photos/1920/1080" },
    },
    {
      component: "image_block",
      block_width: "screen",
      image: { filename: "https://picsum.photos/1920/1080" },
    },
    {
      component: "text_block",
      headline: "Aktuell Sommer 2020",
      text:
        "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken. ",
    },
  ]
  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "theme-burgfestspiele font-body antialiased",
        }}
      />
      <BodyBlocks body={body} />
    </Layout>
  )
}

export default IndexPage
